
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
// import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
// import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { IGetCustomerForViewDto } from "@/shared/service-proxies/service-proxies";
import { CustomersFilter } from "@/models";
import AJLoader from "@/components/AjaxLoader.vue";
import useCustomers from "@/composables/useCustomers";
import DeleteItemService from "@/core/services/DeleteItemService";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import { toolbarWidthFluid } from "@/core/helpers/config";
import usePriceList from "@/composables/usePriceList";
import { PriceListFilter } from "@/models";
export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    AJLoader,
  },
  setup() {
    const store = useStore();
    store.commit(Mutations.GET_CONFIG);
    const config = store.getters.getConfigurations;
    const locale = config.result.localization.values.RetailDemo;
    const breadcrumbs = {
      title: "Customers",
      breadcrumbs: ["Customers"],
    };
    const permissions = config.result.auth.grantedPermissions;

    const translate = (text) => {
      if (locale[text]) {
        return locale[text];
      } else {
        return text;
      }
    };

    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        name: "Actions",
        key: "actions",
      },
      {
        name: "Urno",
        key: "urno",
        sortingField: "customer.urno",
        sortable: true,
      },
      {
        name: "First name",
        key: "firstName",
        sortingField: "customer.firstName",
        sortable: true,
      },
      {
        name: "Last name",
        key: "lastName",
        sortingField: "customer.lastName",
        sortable: true,
      },
      {
        name: "Phone number",
        key: "phoneNumber",
        sortingField: "customer.phoneNumber",
        sortable: true,
      },
      {
        name: "Outlet name",
        key: "outletName",
        sortingField: "customer.outletName",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortingField: "customer.email",
        sortable: true,
      },
      {
        name: "Price List",
        key: "priceListId",
        sortingField: "customer.priceListId",
        sortable: true,
      },
      {
        name: "Retail Channel",
        key: "retailChannel",
        sortingField: "customer.retailChannel",
        sortable: true,
      },
      {
        name: "Region",
        key: "region",
        sortingField: "customer.region",
        sortable: true,
      },

      {
        name: "Outlet address",
        key: "outletAddress",
        sortingField: "customer.outletAddress",
        sortable: true,
      },
    ]);

    const loaded = ref(false);
    const showAdvanceFilter = ref<boolean>(false);

    const { priceLists, getPriceLists } = usePriceList();

    const { getCustomers, customers, deleteCustomer, exportData } =
      useCustomers();
    let tableData = ref<Array<IGetCustomerForViewDto>>([]);
    const initCustomers = ref<Array<IGetCustomerForViewDto>>([]);

    const defaultCustomerFilter = ref({
      filter: null,
      firstNameFilter: null,
      lastNameFilter: null,
      phoneNumberFilter: null,
      outletNameFilter: null,
      outletAddressFilter: null,
      landmarkFilter: null,
      retailChannelFilter: null,
      regionFilter: null,
      stateFilter: null,
      lGAFilter: null,
      sorting: null,
      skipCount: undefined,
      priceListFilter: "",
      maxResultCount: 1000,
      cancelToken: null,
    } as unknown as CustomersFilter);

    onMounted(async () => {
      // setCurrentPageBreadcrumbs("Customers", ["Apps", "Customers"]);
      await getPriceListData();
      await getData();

      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const defaultPriceListFilter = ref({
      sorting: null,
      skipCount: 0,
      maxResultCount: 1000,
    } as unknown as PriceListFilter);

    const getPriceListData = async () => {
      await getPriceLists(
        defaultPriceListFilter.value as unknown as PriceListFilter
      );
    };

    const getData = async () => {
      loaded.value = false;

      await getCustomers(
        defaultCustomerFilter.value as unknown as CustomersFilter
      );
      tableData.value = customers.value;
      loaded.value = true;
    };

    const deleteHandler = async (id: string) => {
      await DeleteItemService.delete(id as string, deleteCustomer);
    };
    const exportHandler = async () => {
      exportData(defaultCustomerFilter as unknown as CustomersFilter);
    };

    const searchFunc = async () => {
      await getData();
    };

    return {
      priceLists,
      translate,
      tableData,
      tableHeader,
      checkedCustomers,
      loaded,
      defaultCustomerFilter,
      showAdvanceFilter,
      exportHandler,
      deleteHandler,
      searchFunc,
      toolbarWidthFluid,
      breadcrumbs,
      permissions,
    };
  },
});
